@font-face {
  font-family: "Enduro";
  src:
    url("./woff2/EnduroWeb-Bold.woff2") format("woff2"),
    url("./woff/EnduroWeb-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Enduro";
  src:
    url("./woff2/EnduroWeb-BoldItalic.woff2") format("woff2"),
    url("./woff/EnduroWeb-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Enduro";
  src:
    url("./woff2/EnduroWeb-Italic.woff2") format("woff2"),
    url("./woff/EnduroWeb-Italic.woff") format("woff");
  font-style: italic;
}

@font-face {
  font-family: "Enduro";
  src:
    url("./woff2/EnduroWeb-Light.woff2") format("woff2"),
    url("./woff/EnduroWeb-Light.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Enduro";
  src:
    url("./woff2/EnduroWeb-Medium.woff2") format("woff2"),
    url("./woff/EnduroWeb-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Enduro";
  src:
    url("./woff2/EnduroWeb-MediumItalic.woff2") format("woff2"),
    url("./woff/EnduroWeb-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Enduro";
  src:
    url("./woff2/EnduroWeb-Regular.woff2") format("woff2"),
    url("./woff/EnduroWeb-Regular.woff") format("woff");
  font-weight: 400;
}
