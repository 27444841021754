@font-face {
  font-family: "Kessler";
  src:
    url("./woff2/KesslerTextWeb-Italic.woff2") format("woff2"),
    url("./woff/KesslerTextWeb-Italic.woff") format("woff");
  font-style: italic;
}

@font-face {
  font-family: "Kessler";
  src:
    url("./woff2/KesslerTextWeb-Light.woff2") format("woff2"),
    url("./woff/KesslerTextWeb-Light.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Kessler";
  src:
    url("./woff2/KesslerTextWeb-Medium.woff2") format("woff2"),
    url("./woff/KesslerTextWeb-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Kessler";
  src:
    url("./woff2/KesslerTextWeb-MediumItalic.woff2") format("woff2"),
    url("./woff/KesslerTextWeb-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Kessler";
  src:
    url("./woff2/KesslerTextWeb-Regular.woff2") format("woff2"),
    url("./woff/KesslerTextWeb-Regular.woff") format("woff");
  font-weight: 400;
}
